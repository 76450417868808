import { Button } from 'antd'

export const RemoveOperator = ({
	onClose,
	onDelete,
	name,
}: {
	onClose: () => void
	onDelete: () => void
	name: string
}) => {
	return (
		<div>
			<div style={{ marginBottom: '10px' }}>
				Вы действительно хотите удалить аккаунт: {name} ?
			</div>

			<div className="terminals__form-add-bottom">
				<Button htmlType="button" onClick={onClose}>
					Отмена
				</Button>
				<Button htmlType="submit" type="primary" danger onClick={onDelete}>
					Удалить
				</Button>
			</div>
		</div>
	)
}
