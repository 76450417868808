import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'

import { setIsCalling, setTypeCall, useAppDispatch } from '@/store'

import { WEBRTC_URL } from '@/configs'

import { terminalCallAPI } from '@/api'

import { ManagementCamera } from '../manageCamera/ManagementCamera'

interface Props {
	id: string
	terminalId: string
	onClose: () => void
}

export const WebRTCSession = forwardRef(
	({ id, terminalId, onClose }: Props, ref) => {
		const iframeRef = useRef<HTMLIFrameElement>(null)
		const dispatch = useAppDispatch()

		useEffect(() => {
			dispatch(setIsCalling(true))

			const listener = (event: any) => {
				if (event.data && event.data.type) {
					if (event.data.type === 'room-deleted') {
						onClose()
					}
				}
			}

			window.addEventListener('message', listener, false)
			return () => {
				window.removeEventListener('message', listener, false)
				dispatch(setIsCalling(false))
				dispatch(setTypeCall(null))
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		const sendMessageToIframe = (message: { type: string; room: string }) => {
			if (iframeRef.current && iframeRef.current.contentWindow) {
				iframeRef.current.contentWindow.postMessage(message, '*')
			}
		}

		const deleteRoom = () => {
			if (iframeRef.current && iframeRef.current.contentWindow) {
				iframeRef.current.contentWindow.postMessage(
					{ type: 'room-deleted', room: terminalId },
					'*'
				)
			}
		}

		useImperativeHandle(ref, () => ({
			sendMessage: sendMessageToIframe,
			deleteRoom: deleteRoom,
		}))

		return (
			<div className="webrtc">
				<iframe
					title="webrtc"
					src={`${WEBRTC_URL}/${terminalId}`}
					style={{ width: '100%', height: '100%' }}
					allow="camera; microphone; fullscreen"
					loading="lazy"
					ref={iframeRef}
				></iframe>
				<ManagementCamera id={id} />
			</div>
		)
	}
)
