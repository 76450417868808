import { Badge, BadgeProps, Calendar, CalendarProps, Modal } from 'antd'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import { useEffect, useState } from 'react'
import { loadingStatus } from 'store/app'
import { useAppDispatch } from 'store/hooks'
import { WorkTimeType } from 'utils/types/commonType'

import { terminalAPI } from '@/api'

import DefaultWorkTime from './DefaultWorkTime'
import EditDayWorkTime from './EditDayWorkTime'

dayjs.locale('ru')

export default function WorkTime({ id }: { id: string }) {
	const [currentDay, setCurrentDay] = useState<null | Dayjs>(null)
	const [currentExclusion, setCurrentExclusion] = useState<null | WorkTimeType>(
		null
	)
	const [exclusions, setExclusions] = useState<[] | Array<WorkTimeType>>([])
	const [open, setOpen] = useState<boolean>(false)
	const [currentDate, setCurrentDate] = useState<any>(null)

	const dispatch = useAppDispatch()

	const getListData = (value: Dayjs) => {
		let listData: { type: string; content: string | null; full?: string }[] = [] // Specify the type of listData

		if (value.day() === 6 || value.day() === 0) {
			listData = [{ type: 'weekend', content: '' }]
		} else {
			listData = [{ type: 'weekday', content: '' }]
		}
		exclusions.forEach((e: WorkTimeType) => {
			if (dayjs(e.date).format('YYYY-MM-DD') === value.format('YYYY-MM-DD')) {
				let isDay =
					value.day() === 6 || value.day() === 0 ? 'weekday' : 'weekend'
				let time = null
				if (e?.endTime && e?.startTime) {
					time = `${dayjs(e.startTime, 'HH:mm:ss').format('HH:mm:ss')} - ${dayjs(e.endTime, 'HH:mm:ss').format('HH:mm:ss')}`
				}
				listData = [{ type: 'edit', content: time, full: isDay }]
			}
		})

		return listData || []
	}

	const dateFullCellRender = (value: Dayjs) => {
		const listData = getListData(value)
		return (
			<div
				className={`work-time__item-calendar ${listData[0].type === 'weekend' && ' _weekend'} ${listData[0].type === 'weekday' && ' _weekday'} ${listData[0].type === 'edit' && ' _edit'}`}
			>
				<div className="work-time__item-date">{value.date()}</div>
				{listData[0].content ? (
					<div className="work-time__item-time">{listData[0].content}</div>
				) : listData[0].full ? (
					listData[0].full === 'weekend' ? (
						<div className="work-time__item-text">Выходной</div>
					) : (
						<div className="work-time__item-text">Будни</div>
					)
				) : (
					''
				)}
			</div>
		)
	}

	const cellRender: CalendarProps<Dayjs>['cellRender'] = (current, info) => {
		if (info.type === 'date') return dateFullCellRender(current)
		return info.originNode
	}

	const handlePanelChange = (date: Dayjs) => {
		const { start, next } = getRangeMonth(date)
		setCurrentDate({ start, next })
		fetc(start, next)
	}

	const fetc = async (back: string, next: string) => {
		dispatch(loadingStatus(true))
		try {
			if (back) {
				const { data } = await terminalAPI.getDateExclusions({
					TerminalId: id,
					DateStart: back,
					DateEnd: next,
				})
				setExclusions(data)
			} else {
				const { start, next } = currentDate
				const { data } = await terminalAPI.getDateExclusions({
					TerminalId: id,
					DateStart: start,
					DateEnd: next,
				})
				setExclusions(data)
			}
		} catch (e) {
			console.error(e)
		} finally {
			dispatch(loadingStatus(false))
		}
	}

	const getRangeMonth = (month: Dayjs) => {
		const monthBack = month.month(month.month() - 2)
		const monthNext = month.month(month.month() + 2)
		return {
			start: monthBack.format('YYYY-MM-DD'),
			next: monthNext.format('YYYY-MM-DD'),
		}
	}

	const onEditDay = (date: Dayjs) => {
		setCurrentDay(date)
		let one = null
		exclusions.forEach((e: WorkTimeType) => {
			if (dayjs(e.date).format('YYYY-MM-DD') === date.format('YYYY-MM-DD')) {
				one = e
			}
		})
		const { start, next } = getRangeMonth(date)
		setCurrentDate({ start, next })
		fetc(start, next)
		setCurrentExclusion(one)
		setOpen(true)
	}
	const closeModal = () => {
		setOpen(false)
	}

	const onReloadData = () => {
		const now = dayjs()
		const { start, next } = getRangeMonth(now)
		fetc(start, next)
	}

	useEffect(() => {
		onReloadData()
	}, [])

	return (
		<div className="work-time">
			<DefaultWorkTime id={id} fetch={onReloadData} />
			<div className="work-time__title _h4">Список исключений</div>

			<ul className="work-time__info-inner">
				<li className="work-time__info-inner-item">
					<span
						className="work-time__info-inner-color"
						style={{ background: '#e1faeb' }}
					></span>
					<span> - будни</span>
				</li>
				<li className="work-time__info-inner-item">
					<span
						className="work-time__info-inner-color"
						style={{ background: '#fae1e1' }}
					></span>
					<span> - выходной</span>
				</li>
				<li className="work-time__info-inner-item">
					<span
						className="work-time__info-inner-color"
						style={{ background: '#fdfadc' }}
					></span>
					<span> - измененный</span>
				</li>
			</ul>

			<Calendar
				fullCellRender={cellRender}
				onPanelChange={handlePanelChange}
				onSelect={(date, { source }) => {
					if (source === 'date') {
						onEditDay(date)
					}
				}}
			/>
			<Modal
				title="Редактирование дня"
				open={open}
				onOk={closeModal}
				onCancel={closeModal}
				footer={null}
				destroyOnClose={true}
			>
				<EditDayWorkTime
					currentExclusion={currentExclusion}
					terminalId={id}
					close={closeModal}
					day={currentDay}
					fetc={fetc}
				/>
			</Modal>
		</div>
	)
}
