import { Button, Modal } from 'antd'
import { useState } from 'react'

import { statusDevice } from '@/utils'

import { SettingAcquiringModal, SettingPrinterModal } from '../modal'

interface Props {
	TerminalId?: string
	title: string
	status: any
	icon?: string
	setting?: boolean
}

export const Equipment = ({
	TerminalId,
	title,
	status,
	setting,
	icon,
}: Props) => {
	const [openSettingPrinter, setOpenSettingPrinter] = useState<boolean>(false)
	const [openSettingAcquiring, setOpenSettingAcquiring] =
		useState<boolean>(false)

	return (
		<>
			<div className="device" data-name={title}>
				<p className="device__title">
					{title} <img src={icon} alt="terminal__device-btn" />
				</p>
				<p
					className={`device__status device__status--${status === 1 ? 'success' : 'error'}`}
				>
					{statusDevice[status!]}
				</p>
				{setting && (
					<Button
						onClick={() => {
							if (title === 'Принтер') {
								setOpenSettingPrinter(true)
							}
							if (title === 'Эквайринг') {
								setOpenSettingAcquiring(true)
							}
						}}
					>
						Настроить
					</Button>
				)}
			</div>
			<Modal
				title={`Настройка принтера`}
				open={openSettingPrinter}
				onOk={() => setOpenSettingPrinter(false)}
				onCancel={() => setOpenSettingPrinter(false)}
				footer={null}
			>
				<SettingPrinterModal />
			</Modal>
			<Modal
				title={`Настройка эквайринга`}
				open={openSettingAcquiring}
				onOk={() => setOpenSettingAcquiring(false)}
				onCancel={() => setOpenSettingAcquiring(false)}
				footer={null}
			>
				<SettingAcquiringModal TerminalId={TerminalId!} />
			</Modal>
		</>
	)
}
