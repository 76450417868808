import { Switch } from 'antd'
import React from 'react'

import {
	getTerminal,
	loadingStatus,
	terminalItem,
	useAppDispatch,
} from '@/store'

import { openErrorNotification, openSuccessNotification } from '@/utils'

import { terminalAPI } from '@/api'

export default function TerminalMaintenance({
	terminalId,
	terminalInfo,
}: {
	terminalId: string
	terminalInfo: terminalItem
}) {
	const dispatch = useAppDispatch()
	const onChange = async (checked: boolean) => {
		dispatch(loadingStatus(true))

		try {
			await terminalAPI.switchMaintenance({ terminalId })
			dispatch(getTerminal(terminalId))
			openSuccessNotification('Статус режима обслуживания изменен')
		} catch (e: any) {
			console.error(e)
			openErrorNotification('Ошибка изменения статуса режима обслуживания')
		} finally {
			dispatch(loadingStatus(false))
		}
	}
	return (
		<div className="terminalSettings__maintenance">
			<div className="terminalSettings__maintenance-title _h4">
				Статус обслуживания
			</div>
			<div className="terminalSettings__maintenance-input">
				<Switch
					checkedChildren="Вкл"
					unCheckedChildren="Выкл"
					onChange={onChange}
					value={terminalInfo.isMaintenance}
				/>
			</div>
		</div>
	)
}
