import { ManagementCamera } from '..'
import { CloseOutlined } from '@ant-design/icons'
import { API_URL } from 'configs/url'
import { useEffect } from 'react'

const jsmpeg = require('jsmpeg')

interface Props {
	id: string
	onClose: () => void
}

export function RtspSession({ id, onClose }: Props) {
	useEffect(() => {
		const client = new WebSocket(`${API_URL}/proxy_ws/camera?TerminalId=${id}`)
		const canvas = document.getElementById('video-canvas')

		new jsmpeg(client, {
			canvas: canvas,
			audio: false,

			//videoBufferSize: 1024 * 1024,
		})

		return () => {
			client.close()
		}
	}, [])

	return (
		<div className="rtsp">
			<canvas id="video-canvas"></canvas>
			<ManagementCamera id={id} />
			<CloseOutlined className="rtsp__close" onClick={onClose} />
		</div>
	)
}
