import Ekvairing from '../../assets/images/ekvairing.png'
import Kkm from '../../assets/images/kkm.png'
import Printer from '../../assets/images/printer.png'
import Skaner from '../../assets/images/skaner.png'

export const nameDevice: {
	[key: string]: { name: string; icon: string }
} = {
	1: {
		name: 'Принтер',
		icon: Printer,
	},
	2: {
		name: 'Сканер',
		icon: Skaner,
	},
	3: {
		name: 'KKM',
		icon: Kkm,
	},
	4: {
		name: 'Эквайринг',
		icon: Ekvairing,
	},
	5: {
		name: 'Камера',
		icon: Printer,
	},
}

export const statusDevice: {
	[key: string]: string
} = {
	1: 'Онлайн',
	2: 'Оффлайн',
	3: 'Ошибка',
}
