import { UploadOutlined } from '@ant-design/icons'
import { Button, Table, TableColumnsType, Upload } from 'antd'

import { loadingStatus, useAppDispatch } from '@/store'

import { ScreenSaversType } from '@/utils'

import { backgroundVideosAPI } from '@/api'

interface Props {
	list: ScreenSaversType[]
	terminalId: string
	type: 'rr' | 'screensaver'
	AdType?: 1 | 2
	getList: () => void
}

export function FileUploadModal({
	list,
	terminalId,
	type,
	AdType,
	getList,
}: Props) {
	const dispatch = useAppDispatch()

	const columns: TableColumnsType<ScreenSaversType> = [
		{
			title: 'Название',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Управление',
			width: 100,
			render: (obj) => {
				return (
					<div>
						<Button
							onClick={async () => {
								dispatch(loadingStatus(true))
								try {
									await backgroundVideosAPI.uploadAdvertisementScreensaverArm({
										videoId: obj.id,
										terminalId,
									})
									await getList()
								} catch (error) {
									console.error(error)
								} finally {
									dispatch(loadingStatus(false))
								}
							}}
							disabled={obj.astiPs.some(
								(astip: any) => astip.id === terminalId
							)}
						>
							{obj.astiPs.some((astip: any) => astip.id === terminalId)
								? 'Закреплено'
								: 'Закрепить'}
						</Button>{' '}
					</div>
				)
			},
		},
	]

	return (
		<div>
			<div className="terminalSettings__inner">
				<Upload
					beforeUpload={async (file) => {
						dispatch(loadingStatus(true))
						try {
							if (type === 'rr') {
								await backgroundVideosAPI.loadAdvertisement({
									AdType: AdType!,
									UploadedFile: file,
								})
								await getList()
								return false
							}
							if (type === 'screensaver') {
								await backgroundVideosAPI.uploadScreenSavers({
									UploadedFile: file,
								})
								await getList()
								return false
							}
						} catch (error) {
						} finally {
							dispatch(loadingStatus(false))
						}
					}}
					showUploadList={false}
				>
					<Button icon={<UploadOutlined />}>Загрузить новую</Button>
				</Upload>
			</div>
			<div className="terminalSettings__list">
				<Table columns={columns} dataSource={list} />
			</div>
		</div>
	)
}
