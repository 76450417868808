import { Modal } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { terminalItem } from '@/store'

import { API_URL } from '@/configs'

import { TerminalListItem } from './TerminalListItem'
import { AddNewStatus } from './modal'

export type currentTerminalType = {
	id: string
	status: string
}

export function TerminalList({
	listTerminals,
	fetch,
}: {
	listTerminals: Array<terminalItem> | null
	fetch: () => void
}) {
	const [addNewStatusIsOpen, setAddNewStatusIsOpen] = useState(false)
	const [currentTerminal, setCurrentTerminal] =
		useState<null | currentTerminalType>(null)
	const [statuses, setStatuses] = useState<null | any>(null)

	const memoizedListTerminals = useMemo(() => listTerminals, [listTerminals])

	const closeModal = useCallback(() => {
		setAddNewStatusIsOpen(false)
	}, [])

	useEffect(() => {
		const socket = new WebSocket(`${API_URL}/terminals/get_terminals_statuses`)

		socket.addEventListener('message', (event) => {
			setStatuses((prevStatuses: any) => {
				if (event?.data) {
					const newStatuses = JSON?.parse(event?.data)
					return newStatuses !== prevStatuses ? newStatuses : prevStatuses
				}
			})
		})

		return () => {
			socket.close()
		}
	}, [])

	return (
		<>
			{memoizedListTerminals && memoizedListTerminals.length > 0 ? (
				<ul className="terminals__list">
					{memoizedListTerminals.map((item, index) => (
						<TerminalListItem
							key={item.id}
							item={item}
							operators={item.operators}
							statuses={statuses ? statuses[index] : null}
						/>
					))}
				</ul>
			) : (
				<div>Нет ни одного терминала</div>
			)}
			<Modal
				title="Установка статуса"
				open={addNewStatusIsOpen}
				onOk={closeModal}
				onCancel={closeModal}
				footer={null}
			>
				<AddNewStatus
					currentTerminal={currentTerminal}
					fetch={fetch}
					onClose={closeModal}
				/>
			</Modal>
		</>
	)
}
