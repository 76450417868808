import {
	ArrowsAltOutlined,
	CloseOutlined,
	ShrinkOutlined,
} from '@ant-design/icons'
import { API_URL } from 'configs/url'
import { useRef, useState } from 'react'
import { VncScreen } from 'react-vnc'

import { Loading } from '../Loading/Loading'

interface Props {
	onClose: () => void
	id: string
}

export function VncSession({ onClose, id }: Props) {
	const [isFullscreen, setFullscreen] = useState(false)
	const vncContainerRef = useRef<HTMLDivElement>(null)

	const handleFullscreenToggle = () => {
		setFullscreen(!isFullscreen)

		// После изменения состояния, подождите, пока DOM обновится
		setTimeout(() => {
			const vncInnerDiv = vncContainerRef.current?.querySelector(
				'div[style*="height: 100vh"]'
			)
			if (vncInnerDiv) {
				vncInnerDiv.setAttribute('style', 'width: 100%; height: 100%;')
			}
		}, 0)
	}

	return (
		<div
			className={`vnc ${isFullscreen ? 'vnc--fullscreen' : ''}`}
			ref={vncContainerRef}
		>
			<VncScreen
				url={`${API_URL}/proxy_ws/vnc?TerminalId=${id}`}
				scaleViewport
				style={{
					width: '100%',
					height: isFullscreen ? '100vh' : '22vh',
				}}
				loadingUI={<Loading />}
			/>
			{isFullscreen ? (
				<ShrinkOutlined
					className="vnc__arrows"
					onClick={handleFullscreenToggle}
				/>
			) : (
				<ArrowsAltOutlined
					className="vnc__arrows"
					onClick={handleFullscreenToggle}
				/>
			)}
			<CloseOutlined className="rtsp__close" onClick={onClose} />
		</div>
	)
}
