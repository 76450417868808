import { LoginOutlined } from '@ant-design/icons'
import { useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'

import {
	getStatusList,
	operatorItem,
	terminalItem,
	useAppDispatch,
} from '@/store'

import { TERMINALS_PATH, nameDevice, statusDevice } from '@/utils'

import mapIcon from '../../../assets/images/baseline-place.svg'
import ipIcon from '../../../assets/images/eos-icons_ip.svg'
import operatorsIcon from '../../../assets/images/fluent_people.svg'

import TerminalOperators from './TerminalOperators'

interface TerminalListItemProps {
	item: terminalItem
	statuses: {
		status: string
		devicesStatus: {
			printerStatus: string
			scanerStatus: string
			kkmStatus: string
			acquiringStatus: string
		}
	} | null
	operators?: operatorItem[]
}

const statusName: {
	[key: string]: {
		status: string
		class: string
	}
} = {
	Online: {
		status: 'В сети',
		class: '_active',
	},
	Offline: {
		status: 'Не в сети',
		class: '_offline',
	},
	Disabled: {
		status: 'Отключен',
		class: '_offline',
	},
	None: {
		status: 'Ошибка',
		class: 'None',
	},
}

export function TerminalListItem({
	item,
	statuses,
	operators,
}: TerminalListItemProps) {
	const dispatch = useAppDispatch()

	useEffect(() => {
		dispatch(getStatusList())
	}, [dispatch])

	// Мемоизация статусов устройств
	const deviceStatus = useMemo(
		() => ({
			printerStatus: statuses?.devicesStatus?.printerStatus,
			scanerStatus: statuses?.devicesStatus?.scanerStatus,
			kkmStatus: statuses?.devicesStatus?.kkmStatus,
			acquiringStatus: statuses?.devicesStatus?.acquiringStatus,
		}),
		[statuses]
	)

	return (
		<li className={`terminals__item ${statusName[item.astipStatus].class}`}>
			<div
				className={`terminals__item-status ${statusName[item.astipStatus].class}`}
			></div>
			<div className="terminals__top">
				<div className="terminals__left">
					<div className="terminals__name">
						<Link to={`${TERMINALS_PATH}/${item.id}`}>{item.name}</Link>
					</div>
					<div className="terminals__info">
						<div className="terminals__info-item">
							<img
								className="terminals__info-icon"
								src={mapIcon}
								alt="terminals__info"
							/>
							<div className="terminals__address">{item?.address}</div>
						</div>
						<div className="terminals__info-item">
							<img
								className="terminals__info-icon"
								src={ipIcon}
								alt="terminals__info"
							/>
							<div className="terminals__address">{item?.currentIP}</div>
						</div>
						<div className="terminals__info-item">
							<img
								className="terminals__info-icon"
								src={operatorsIcon}
								alt="terminals__info"
							/>
							<div className="terminals__info-title">Операторы:</div>
						</div>
						{operators && <TerminalOperators operators={operators} />}
					</div>
				</div>
				<div className="terminals__devices-wrap">
					<div className="terminals__devices-title">Статус устройства:</div>
					<div className="terminals__devices">
						{item?.devices.length ? (
							item?.devices?.map((item, index) => (
								<div className="terminal__device" key={index}>
									<div className="terminal__device-title-wrap">
										<span>{nameDevice[item.type].name}: </span>

										<div
											className={`terminal__device-status ${
												item.status === 1 ? '_active' : ''
											}`}
											data-status={item.status}
										>
											{statusDevice[item.status!]}
										</div>
									</div>
								</div>
							))
						) : (
							<p>Данных нет</p>
						)}
					</div>
				</div>
			</div>

			<Link to={`${TERMINALS_PATH}/${item.id}`} className="terminals__link btn">
				<span className="terminals__link-text">Перейти к терминалу </span>
				{/* <LoginOutlined style={{ fontSize: '15px' }} /> */}
			</Link>
		</li>
	)
}
