import { Button, DatePicker } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { start } from 'repl'
import { loadingStatus } from 'store/app'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import { getTerminal } from '@/store'

import { openErrorNotification, openSuccessNotification } from '@/utils'

import { terminalAPI } from '@/api'

const { RangePicker } = DatePicker

export default function DefaultWorkTime({
	id,
	fetch,
}: {
	id: string
	fetch: any
}) {
	const [newTime, setNewTime] = useState<null | any>(null)
	const [error, setError] = useState<boolean>(false)
	const terminalInfo = useAppSelector(
		(state) => state.terminals.currentTerminal
	)
	const dispatch = useAppDispatch()

	const onSave = async () => {
		dispatch(loadingStatus(true))
		try {
			if (
				newTime &&
				newTime.length === 2 &&
				newTime[0] !== '' &&
				newTime[1] !== ''
			) {
				openSuccessNotification('Дефолтное время работы установлено')
				setError(false)
				fetch()
				dispatch(getTerminal(id))
			} else {
				setError(true)
			}
			await terminalAPI.setDefaultWorkTime({
				terminalId: id,
				startWorkTime: dayjs(newTime[0], 'HH:mm:ss').format('HH:mm:ss'),
				endWorkTime: dayjs(newTime[1], 'HH:mm:ss').format('HH:mm:ss'),
			})
		} catch (e) {
			console.error(e)
			openErrorNotification('Ошибка при установки дефолтного времени')
		} finally {
			dispatch(loadingStatus(false))
		}
	}

	const onChangeTime = (dates: any, dateString: any) => {
		setNewTime(dateString)
	}

	useEffect(() => {
		if (terminalInfo) {
			setNewTime([
				dayjs(terminalInfo.startWorkTime, 'HH:mm:ss').format('HH:mm:ss'),
				dayjs(terminalInfo.endWorkTime, 'HH:mm:ss').format('HH:mm:ss'),
			])
		}
	}, [terminalInfo])
	return (
		<>
			<div className="work-time__title _h4">Стандартное время работы</div>
			<div>Изменить время работы</div>
			{terminalInfo && (
				<>
					{' '}
					<RangePicker
						className="work-time__default-time"
						picker="time"
						id={{
							start: 'startInput',
							end: 'endInput',
						}}
						defaultValue={[
							dayjs(terminalInfo.startWorkTime, 'HH:mm:ss'),
							dayjs(terminalInfo.endWorkTime, 'HH:mm:ss'),
						]}
						onChange={onChangeTime}
					/>
					<div className={`work-time__default-eror ${error && '_active'}`}>
						Заполните все поля
					</div>
					<Button className="work-time__default-time-btn btn" onClick={onSave}>
						Сохранить
					</Button>
				</>
			)}
		</>
	)
}
