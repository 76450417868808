import EmailConfig from 'components/EmailConfig/EmailConfig'

export function SettingsPage() {
	return (
		<div className="settings">
			<div className="settings__title title">Настройки</div>
			<EmailConfig />
		</div>
	)
}
