import { Tabs, TabsProps } from 'antd'
import Payments from 'components/Payments/Payments'
import Readings from 'components/Readings/Readings'

const items: TabsProps['items'] = [
	{
		key: '1',
		label: 'Список платежей',
		children: (
			<div className="reports__inner">
				<Payments />
			</div>
		),
	},
	{
		key: '2',
		label: 'Список показаний',
		children: (
			<div className="reports__inner">
				<Readings />
			</div>
		),
	},
]

export function ReportsPage() {
	return (
		<div className="reports">
			<div className="reports__title title">Отчеты</div>
			<Tabs
				defaultActiveKey="1"
				items={items}
				type="card"
				size="large"
				className="reports__tabs"
			/>
		</div>
	)
}
